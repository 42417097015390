import { render } from 'react-dom';
import './index.css';

async function createApp() {
  const { App } = await import('./App');
  const container = document.getElementById('main');
  const app = App();

  render(app, container);
}

createApp();

declare const module: any;
if (module.hot) {
  module.hot.accept();
}
